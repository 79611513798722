
















import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
@Component({})
export default class Breadcrumb extends Vue {
    @Getter("getLang") lang: any;
    @Getter("getUser") getUser: any;
    @Getter("getAssessPermission") assessPermission: any;

    data: any = {
        breadcrumbData: [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            },
            {
                routeName: "userinfo",
                routeContent: this.lang == "en" ? "User Center" : "个人中心"
            }
        ]
    };

    @Watch("$route")
    routeChange(to: any, from: any) {
        // console.log(to);
        
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            },
            {
                routeName: "userinfo",
                routeContent: this.lang == "en" ? "User Center" : "个人中心"
            }
        ];
        this.setBreadcrumb(to);
    }

    @Watch("lang")
    langChange(nval: any, oval: any) {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            },
            {
                routeName: "userinfo",
                routeContent: this.lang == "en" ? "User Center" : "个人中心"
            }
        ];
        this.setBreadcrumb(this.$route);
    }

    setBreadcrumb(route: any) {
        // console.log(this.assessPermission);
            
    
        let arry: any;

        if (route.name != "assess") {
            let routeKey = route.matched.findIndex(
                (val: any) => val.name == route.name
            );
            let fatherRouteKey = routeKey - 1;
            let fatherRoute = route.matched[fatherRouteKey];
            if (fatherRoute.name == "assess") {
                arry = [
                    {
                        routeName: "assessList",
                        routeContent:
                            this.$i18n.locale == "en"
                                ? fatherRoute.meta.content.en
                                : fatherRoute.meta.content.zh,
                        params: {
                            eid: this.getUser().expert_id,
                            id: this.assessPermission[0].review_id
                        }
                    },
                    {
                        routeName: route.name,
                        routeContent:
                            this.$i18n.locale == "en"
                                ? route.meta.content.en
                                : route.meta.content.zh
                    }
                ];
            } else {
                arry = [
                    {
                        routeName: fatherRoute.name,
                        routeContent:
                            this.$i18n.locale == "en"
                                ? fatherRoute.meta.content.en
                                : fatherRoute.meta.content.zh
                        // params: {
                        //     eid: this.user.expert_id,
                        //     id: this.assessPermission[0].review_id
                        // }
                    },
                    {
                        routeName: route.name,
                        routeContent:
                            this.$i18n.locale == "en"
                                ? route.meta.content.en
                                : route.meta.content.zh
                    }
                ];
            }
        } else {
            arry = [
                {
                    routeName: route.name,
                    routeContent:
                        this.$i18n.locale == "en"
                            ? route.meta.content.en
                            : route.meta.content.zh
                }
            ];
        }

        this.data.breadcrumbData = this.data.breadcrumbData.concat(arry);

        // console.log(this.data.breadcrumbData);
    }

    created() {}
    mounted() {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            },
            {
                routeName: "userinfo",
                routeContent: this.lang == "en" ? "User Center" : "个人中心"
            }
        ];
        // console.log(this.data.breadcrumbData);
        this.setBreadcrumb(this.$route);
    }
    activated() {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            },
            {
                routeName: "userinfo",
                routeContent: this.lang == "en" ? "User Center" : "个人中心"
            }
        ];
        // console.log(this.data.breadcrumbData);
        this.setBreadcrumb(this.$route);
        // console.log(this.$router.options.routes)
    }
}
