















import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import Breadcrumb from "@/components/assess/Breadcrumb.vue";
@Component({
    components: {
        breadcrumb: Breadcrumb
    }
})
export default class Assess extends Vue {
    @Getter("getAssessPermission") assessPermission: any;
    @Action("getSetAssessPermission") setAssessPermission: any;
    @Getter("getUser") getUser: any;

    loading: any = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
    });

    get user() {
        return this.getUser();
    }

    havePermission: boolean = false;

    @Watch("user")
    userChange(nv: any, ov: any) {
        if (nv) {
            this.checkPermission();
        }
    }


    checkPermission() {
        let eid = this.user.expert_id;

        if (!this.assessPermission || this.assessPermission.length <= 0) {
            if (eid) {
                const getOpt = this.$api.apiList.getAssessPermission;
                const opt = getOpt(eid);
                this.$axios({
                    url: opt.url,
                    method: opt.method,
                    headers: opt.headers
                })
                    .then((res: any) => {
                        this.setAssessPermission(res.data.data);
                        if (res.data.data.length > 0) {
                            this.havePermission = true;
                            this.loading.close();
                        } else {
                            this.havePermission = false;
                            this.$router.push({ name: "userinfo" });
                        }
                    })
                    .catch((err: any) => {
                        // console.log(err);
                        this.havePermission = false;
                        // this.$notify({
                        //     title: "警告",
                        //     message: "获取评审权限失败",
                        //     type: "warning"
                        // });
                    });
            }
        } else {
            this.havePermission = true;
            this.loading.close();
        }
    }

    created() {
        this.checkPermission();
    }

    destroyed() {
        this.loading.close();
        this.loading = "false";
    }
}
